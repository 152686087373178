<template>
    <a
        :href="link"
        class="event-item flex items-center cursor-pointer pt-8 gap-8 s:flex-wrap border-t border-black"
    >
        <div class="flex gap-8 xs:gap-4 w-full xs:flex-wrap items-center">
            <DateBadge :dateString="event.nextOccurrence" />
            <div>
                <div class="flex flex-wrap gap-4 xs:gap-2 xs:flex-col">
                    <Tag
                        v-if="event.additionalType"
                        :text="event.additionalType"
                        :iconName="CONFIG_OPTIONS.global.categoryIcon"
                    />
                    <Tag
                        v-if="event.address && event.address.addressLocality"
                        iconName="fa-map-pin"
                        :text="event.address.addressLocality"
                        variant="light"
                    />
                </div>
                <div class="text-f5 pt-4 md:pt-2 max-w-lg inline-block">
                    {{ event.name }}
                </div>
            </div>
        </div>
        <div class="btn-light ml-auto s:w-full">Zum Event</div>
    </a>
</template>

<script lang="ts" setup>
import { CONFIG_OPTIONS } from "@src/globals";
import DateBadge from "@src/components/common/DateBadge.vue";
import Tag from "@src/components/common/Tag.vue";

const props = defineProps({
    link: String,
    event: Object,
});
</script>
